import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Image, Link, RichText, SEO } from '@src/components';
import { Arrow } from '@src/svgs';
import { className, SanityLink } from '@src/utils';
import styles from './styles/about.module.scss';

const AboutPage = ({ data: { page } }) => (
  <Fragment>
    <SEO seo={page.seo} title={page.title} />
    <h1 className={styles.title}>{page.title}</h1>
    <div className={styles.main}>
      <aside className={styles.headshotWrapper}>
        <div className={styles.headshot}>
          <Image value={page.headshot} />
        </div>
      </aside>
      <div className={styles.inner}>
        <RichText className={styles.content} content={page.content} />
        {page.links && !!page.links.length && (
          <section {...className(styles.section, styles.linkSection)}>
            <h3 {...className(styles.label, styles.linkLabel)}>Links</h3>
            <ul className={styles.linkList}>
              {page.links.map(sanityLink => {
                const link = SanityLink.resolve(sanityLink);
                return (
                  <li key={link.key} className={styles.linkItem}>
                    <Link to={link.to} className={styles.link}>
                      <span>{link.text}</span>
                      <div className={styles.arrow}>
                        <Arrow />
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </section>
        )}
        {page.credits && !!page.credits.length && (
          <section {...className(styles.section, styles.creditsSection)}>
            <RichText className={styles.credits} content={page.credits} />
          </section>
        )}
      </div>
    </div>
  </Fragment>
);

AboutPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }).isRequired,
};

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
    page: sanityAboutPage {
      title
      headshot {
        altText
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      links {
        ...SanityLink
      }
      credits: _rawCredits(resolveReferences: { maxDepth: 10 })
      seo {
        ...SanitySeo
      }
    }
  }
`;
